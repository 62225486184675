::-webkit-scrollbar {
    width: 5px;
  }
   
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
   
  ::-webkit-scrollbar-thumb {
    background-color: #5A88CA;
    outline: 1px solid #5A88CA;
  }


.login_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    margin-top: 30px;
}
.slider_part img{
    width: 100%;
    object-fit: cover;
    object-position: center;
}
.carousel-indicators [data-bs-target]{
    background-color:#FA8072 !important;
}
.carousel-control-prev-icon:after{
    display: none;
}

.carousel-control-next-icon:after{
    display: none;
}
.carousel-control-prev-icon, .carousel-control-next-icon{
    background-color: #FA8072;
}

.card_wrapper{
    transition: box-shadow .3s;
    width: 300px;
    margin: 50px;
    border-radius:10px;
    border: 1px solid #ccc;
    background: #fff;
    float: left;
    padding:0px !important;
}
.card_wrapper:hover{
    box-shadow: 0 0 19px rgba(33,33,33,.2);
}

.product_item_img img{
    /* height: 200px;
    object-fit: cover;
    object-position: center;
    margin-bottom: 15px; */
    width:100%; 
    height: 150px;
    text-align:center
}
@media only screen and (max-width: 600px) {
  .product_item_img img{
    /* height: 200px;
    object-fit: cover;
    object-position: center;
    margin-bottom: 15px; */
    width:50%; 
    height: 100px;
    text-align:center
}

}
@media only screen and (min-width: 480px) and (max-width: 767px)  {
  .product_item_img img{
    /* height: 200px;
    object-fit: cover;
    object-position: center;
    margin-bottom: 15px; */
    /* width:20%; 
    height: 100px;
    text-align:center */
}

}
.product_title{
    font-size:14px;
}
.product_price{
    text-align: center;
    font-size: 20px !important;
    color: teal;
}
.protien{
    padding:10px;
}
.view_more_btn{
    text-decoration: none;
    text-align: center;
    display: block;
    background-color: teal;
    color: white;
    padding:5px 0px;
    border-radius: 10px;
    transition: 0.3s ease-in;
}
.view_more_btn:hover{
    background-color: transparent;
    border:3px solid teal;
    color: teal;
    font-weight: bold;
}

.pagination {
    justify-content: center;
  }
  
  ul {
    list-style: none;
    padding: 0;
  }
  
  ul.pagination li {
    display: inline-block;
    width: 30px;
    border: 1px solid #e2e2e2;
    display: flex;
    justify-content: center;
    font-size: 25px;
  }
  
  ul.pagination li a {
    text-decoration: none;
    color: #337ab7;
    font-size: 20px;
  }
  
  ul.pagination li.active a {
    color: white;
  }
  ul.pagination li.active {
    background-color: #337ab7;
  }
  
  ul.pagination li a:hover,
  ul.pagination li a.active {
    color: blue;
  }
  
  .page-selection {
    width: 48px;
    height: 30px;
    color: #337ab7;
  }
  
  .pagination-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

.sidebar_part{
  background-color: teal !important;
  padding:30px;
}
.footer_part{
  background-color: #FA8072;
  color: white;
}
.product_details_image img{
  height: 300px;
  width: 300px;
}
.product_details_left{
  width: 300px !important;
  padding: 0 !important;
}
.product_details{
  margin-top:30px;
}

.product_details_title{
  font-size:22px;
}

/* navbar */

.navbar-nav .nav-link{
  color: white !important;
  font-weight: bold;
}
.navbar-dark .navbar-toggler-icon {
  background-color: #337ab7;
}