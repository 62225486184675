#image{
      width: 250px;
      height: 230px;
}
#name{
      text-transform: Uppercase; 
}
#cardDiv{
      margin: 2%;

}
#underDiv{
      margin:2% 3% 3%;
     
}

